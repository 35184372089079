import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_API

class ProfileService {   
	get() {
		return axios.get(API_URL + 'profile', { headers: authHeader() });
    }   
    add(lead) {
        return axios.post(API_URL + 'profile/add', lead, { headers: authHeader() });
    }
    update(profileid, profile) {
        return axios.post(API_URL + 'profile/'+profileid, profile, { headers: authHeader() });
    }
}
export default new ProfileService();